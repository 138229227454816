<template>
    <div>
        <v-row>
            <v-col class="white">
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.sites') }}
                    </p>
                </v-subheader>
            </v-col>
        </v-row>
        <v-col>
            <v-card>
                <v-card-title class="d-flex align-center">
                    <v-spacer></v-spacer>
                    <v-autocomplete
                        v-show="logistic_areas.length > 1"
                        id="logistic_area"
                        v-model="chosen_logistic_area"
                        :clearable="true"
                        :filter="filterAreas"
                        :items="orderedAreas"
                        :label="$t('site.logistic_area')"
                        class="px-4 mb-3"
                        item-text="code"
                        item-value="id"
                        name="logistic_area"
                    >
                        <template slot="selection" slot-scope="data">
                            {{ data.item.code }} ({{ data.item.name }})
                        </template>
                        <template slot="item" slot-scope="data">
                            {{ data.item.code }} ({{ data.item.name }})
                        </template>
                    </v-autocomplete>
                    <v-text-field
                        v-model="search"
                        :label="$t('site.search')"
                        append-icon="mdi-magnify"
                        class="mt-4 pa-0"
                        clearable
                        single-line
                    ></v-text-field>
                    <v-col class="d-flex justify-center pa-0" cols="3">
                        <v-btn color="primary" @click="filter">{{ $t('tour.apply') }}</v-btn>
                    </v-col>
                </v-card-title>

                <v-data-table
                    ref="databaseSite"
                    :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page'),
                        'items-per-page-options': [10, 20, 30, 100]
                    }"
                    :headers="headers"
                    :items="sites"
                    :loading="loading"
                    :loading-text="$t('global.loading_text')"
                    :no-data-text="$t('global.no_data')"
                    :no-results-text="$t('global.no_results_text')"
                    :options.sync="options"
                    :page="currentPage"
                    :search="search"
                    :server-items-length="fullSites"
                    class="elevation-1"
                    multi-sort
                    @resolve="action"
                    @update:options="changeOptions"
                >
                    <template v-slot:item.id="{ item }">
                        <span
                            v-for="(company,key) in site_references(item)"
                            v-if="site_references(item).length > 0"
                        >
                            {{ company }}<span v-if="key+1 !== site_references(item).length">,</span>
                        </span>
                    </template>

                    <template v-slot:item.location_state.name="{ item }">
                        <v-badge
                            :color="getGpsColor(item)"
                            :icon="item.location_state_id === 3 ? 'mdi-lock' : ''"
                            bordered
                            overlap
                        >
                            <v-icon :title="getTitleFromGps(item)" left>mdi-crosshairs-gps</v-icon>
                        </v-badge>
                    </template>

                    <template v-slot:item.name="{ item }">
                        {{ item.name|| $t('site.no_data') }}
                    </template>

                    <template v-slot:item.active="{ item }">
                        <v-icon
                            :color="getStatusColor(item.active)"
                            :title="getStatusHover(item.active)"
                        >
                            mdi-circle
                        </v-icon>
                    </template>

                    <template v-slot:item.last_operation_datetime="{ item }">
                        {{site_last_operation(item)}}
                    </template>

                    <template v-slot:item.silo_number="{ item }">
                        {{item.silos.length}}
                    </template>

                    <template v-slot:item.silos="{ item }">
                        <img :src="'images/' + getSiteSecurityLevel(item)" alt="">
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-row>
                            <v-icon
                                small
                                @click="action(item)"
                            >
                                mdi-lead-pencil
                            </v-icon>
                            <v-icon
                                :title="$t('site.go_to_site')"
                                class="mr-2"
                                small
                                @click="goTo(item.id)"
                            >
                                mdi-arrow-right
                            </v-icon>
                        </v-row>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>

        <v-dialog v-model="dialog" max-width="600px">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('site.edit_site') }}</v-card-title>
                <v-card-text>
                    <v-form name="sites">
                        <v-text-field
                            v-model="site_to_update.id"
                            :disabled="true"
                            :label="$t('site.code')"
                            data-vv-name="site.code"
                            name="site.code"
                            prepend-icon="mdi-alpha-c-box"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.name"
                            :label="$t('site.name')"
                            data-vv-name="site.name"
                            name="site.name"
                            prepend-icon="mdi-alpha-n-box"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.address_1"
                            :label="$t('site.address_1')"
                            data-vv-name="site.address_1"
                            name="site.address_1"
                            prepend-icon="mdi-numeric-1-box-multiple-outline"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.address_2"
                            :label="$t('site.address_2')"
                            data-vv-name="site.address_2"
                            name="site.address_2"
                            prepend-icon="mdi-numeric-2-box-multiple-outline"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.address_3"
                            :label="$t('site.address_3')"
                            data-vv-name="site.address_3"
                            name="site.address_3"
                            prepend-icon="mdi-numeric-3-box-multiple-outline"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.city"
                            :label="$t('site.city')"
                            data-vv-name="site.city"
                            name="site.city"
                            prepend-icon="mdi-home-city-outline"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.zip"
                            :label="$t('site.zip_code')"
                            data-vv-name="site.zip_code"
                            name="site.zip_code"
                            prepend-icon="mdi-home-city"
                            type="text"
                        ></v-text-field>

                        <v-select
                            id="site.location_states"
                            v-model="site_to_update.location_state_id"
                            v-validate="'required'"
                            :disabled="true"
                            :items="location_states"
                            :label="$t('site.location_state')"
                            chips
                            data-vv-name="location_states"
                            item-text="name"
                            item-value="id"
                            name="site.location_states"
                            prepend-icon="mdi-lock-outline"
                            required
                        >
                        </v-select>

                        <v-select
                            id="site.status"
                            v-model="site_to_update.active"
                            v-validate="'required'"
                            :items="this.status"
                            :label="$t('site.status')"
                            chips
                            data-vv-name="states"
                            item-text="name"
                            item-value="id"
                            name="site.status"
                            prepend-icon="mdi-moon-full"
                            required
                        >
                        </v-select>

                        <v-text-field
                            v-model="site_to_update.latitude"
                            :label="$t('site.latitude')"
                            data-vv-name="site.latitude"
                            name="site.latitude"
                            prepend-icon="mdi-latitude"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.longitude"
                            :label="$t('site.longitude')"
                            data-vv-name="site.longitude"
                            name="site.longitude"
                            prepend-icon="mdi-longitude"
                            type="text"
                        ></v-text-field>

                        <v-textarea
                            v-model="site_to_update.comments"
                            :label="$t('site.comments')"
                            data-vv-name="site.comments"
                            name="site.comments"
                            prepend-icon="mdi-comment-text-multiple-outline"
                            type="textarea"
                        ></v-textarea>

                        <v-textarea
                            v-model="site_to_update.route_comments"
                            :label="$t('site.route_comments')"
                            data-vv-name="site.route_comments"
                            name="site.route_comments"
                            prepend-icon="mdi-comment-multiple-outline"
                            type="textarea"
                        ></v-textarea>

                        <v-text-field
                            v-model="site_to_update.contact_avril_name"
                            :label="$t('site.contact_name')"
                            data-vv-name="site.contact_name"
                            name="site.contact_name"
                            prepend-icon="mdi-account-box-outline"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.contact_avril_mobile"
                            :label="$t('site.contact_mobile')"
                            data-vv-name="site.contact_mobile"
                            name="site.contact_mobile"
                            prepend-icon="mdi-cellphone"
                            type="text"
                        ></v-text-field>

                        <v-checkbox
                            v-model="site_to_update.silo_scan_code_pairing_is_disabled"
                            :label="$t('site.disable_qrcode_pairing')"
                        />

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="darken-1" text @click="dialog= false">
                                {{ $t('site.cancel') }}
                            </v-btn>
                            <v-btn :disabled="updating" color="warning darken-1"
                                   text @click="updateSite(site_to_update)">
                                {{ $t('site.edit') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" :color="snackbar_type" top>
            {{ snackbar_text }}
            <v-btn text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {AREA_SITES, LOCATION_STATES, SITE, USER_LOGISTIC_AREAS, USER_SITES} from "../api";
import {LMap, LMarker, LTileLayer} from 'vue2-leaflet';
import {latLng} from "leaflet";
import _ from "lodash";

export default {
    name: "Sites",
    components: {
        LMap,
        LTileLayer,
        LMarker
    },
    data() {
        return {
            headers: [
                {text: this.$t('site.site_references'), value: 'id', sortable: false},
                {text: this.$t('site.gps'), value: 'location_state.name', sortable: true},
                {text: this.$t('site.name'), value: 'name', sortable: true},
                {text: this.$t('site.status'), value: 'active', sortable: true},
                //{text: this.$t('site.state'), value: 'state', sortable: true},
                {text: this.$t('site.zip_code'), value: 'zip', sortable: true},
                {text: this.$t('site.city'), value: 'city', sortable: true},
                {text: this.$t('site.last_operation'), value: 'last_operation_datetime', sortable: true},
                {text: this.$t('site.silo_number'), value: 'silo_number', sortable: false},
                {text: this.$t('site.security_level'), value: 'silos', sortable: false},
                {text: this.$t('site.actions'), value: 'actions', sortable: false},
            ],
            status: [
                {name: this.$t('site.active'), id: 1,},
                {name: this.$t('site.inactive'), id: 0,},
            ],
            loading: false,
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
            sites: [],
                fullSites: null,
                options: {page: 1, itemsPerPage: 10},

                currentPage: 1,
                search: '',
                dialog: false,
                site_to_update: {},
                location_states: [],
                updating: false,
                logistic_areas: [],
                chosen_logistic_area: null,
                silos: [],
                dialog_site: false,
                selected_site: {},
                site_location_state: '',
                site_position: {},
            }
        },

        mounted() {
            if (sessionStorage.getItem("search-filter")) {
                this.search = sessionStorage.getItem("search-filter");
            }
            if (sessionStorage.getItem("logistic-area-filter")) {
                this.chosen_logistic_area = parseInt(sessionStorage.getItem("logistic-area-filter"));
            }
            if (sessionStorage.getItem("page-filter")) {
                this.currentPage = parseInt(sessionStorage.getItem("page-filter"));
            }

            this.getLogisticAreas();
        },

        methods: {
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Filter areas drop-down
             */
            filterAreas (item, queryText) {
                const textOne = item.name.toLowerCase();
                const textTwo = item.code.toLowerCase();
                const searchText = queryText.toLowerCase();

                return textOne.indexOf(searchText) > -1 ||
                    textTwo.indexOf(searchText) > -1
            },
            filter()
            {
                this.options.itemsPerPage = 10;
                this.saveFilters();
                if (this.chosen_logistic_area != null) {
                    this.getSitesFromLogisticArea(this.chosen_logistic_area);
                } else {
                    this.getSites();
                }
            },
            saveFilters() {
                if (this.search !== null) {
                    sessionStorage.setItem('search-filter', this.search);
                } else {
                    sessionStorage.removeItem('search-filter');
                }

                if (this.chosen_logistic_area != null) {
                    sessionStorage.setItem('logistic-area-filter', this.chosen_logistic_area);
                } else {
                    sessionStorage.removeItem('logistic-area-filter');
                }

                sessionStorage.removeItem('page-filter');
                this.currentPage = 1;
            },
            /**
             * Get all the sites for the data table.
             *
             * @param {int|null} page
             * @param {int} per_page
             * @param {string|null} filter
             *
             * @returns {Promise<unknown>}
             */
            getSites(page = null, per_page = this.options.itemsPerPage, filter = this.search) {
                if (!filter && sessionStorage.getItem('search-filter')) {
                    filter = sessionStorage.getItem('search-filter');
                }
                this.loading = true;
                this.axios.get(USER_SITES, {params: {page, per_page, filter}}).then(({data: pagination}) => {
                    this.sites = Object.values(pagination.data);
                    this.fullSites = pagination.total;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },
            getSiteSecurityLevel(site)
            {
                let color = "Undefined";
                let security_level = -1;
                site.silos.forEach((item) => {
                    if (item.security_level !== null && item.security_level.level > security_level) {
                        security_level = item.security_level.level;
                        color = item.security_level.color;
                    }
                });

                return this.getSecurityLevelImage(color);
            },
            /**
             * Get pictogram for the security level
             *
             * @param securityLevelColor
             * @returns {string}
             */
            getSecurityLevelImage(securityLevelColor) {
                switch (securityLevelColor) {
                    case 'Green':
                        return 'silo_vert.svg';
                    case 'Orange':
                        return 'silo_orange.svg';
                    case 'Red':
                        return 'silo_rouge.svg';
                    case 'Black':
                        return 'silo_noir.svg';
                    default:
                        return 'silo_neutre.svg';
                }
            },
            /**
             * Get color for the location state.
             *
             * @param status
             */
            getColorFromStatus(status) {
                switch (status) {
                    case 1 :
                        return 'error';
                    case 2 :
                        return 'success';
                    default:
                        return 'secondary';
                }
            },

            /**
             * Get color for the GPS
             * Location state id : 1 => Locked
             * Location state id : 2 => Unlocked
             *
             * @param item
             */
            getGpsColor(item) {
                switch (item.location_state_id) {
                    case 2 :
                    case 3 :
                        return 'success';
                    default :
                        return 'error';
                }
            },

            /**
             * Get the title for the associated GPS
             *
             */
            getTitleFromGps(item) {
                switch (item.location_state_id) {
                    case 2 :
                        return this.$t('site.unlocked');
                    case 3 :
                        return this.$t('site.locked');
                    default:
                        return this.$t('site.not_specified');
                }
            },

            /**
             * Get the color for the status
             *
             * @param active
             */
            getStatusColor(active) {
                return active ? "success" : "error";
            },
            /**
             * Get the title on mouse over action on the status icon
             *
             * @param status
             */
            getStatusHover(status) {
                return status ? this.$t('site.active') : this.$t('site.inactive');
            },

            /**
             * Get the wanted site to update.
             *
             * @param {int} id
             * @returns {Promise<unknown>}
             */
            getSite(id) {
                return new Promise((resolve, reject) => {
                    this.axios.get(this.replacePathParams(SITE, {site: id})).then(success => {
                        this.site_to_update = success.data;
                        resolve();
                    }).catch(() => {
                        this.showSnackbar('error', this.$t('global.error'));
                        reject();
                    })
                });
            },
            /**
             * Get the sites that are associated to a specific logistic area.
             *
             * @param {int} id
             */
            async getSitesFromLogisticArea(id) {
                this.loading = true;
                let page = this.currentPage;
                let per_page = this.options.itemsPerPage;
                let filter = this.search || sessionStorage.getItem('search-filter');
                await this.axios.get(this.replacePathParams(AREA_SITES, {logistic_area: id}), {params: {page, per_page, filter}}).then(({data: pagination}) => {
                    this.sites = Object.values(pagination.data);
                    this.fullSites = pagination.total;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },

            /**
             * Action used when we click on the update button
             *
             * @param {object} item
             */
            async action(item) {
                await this.getSite(item.id);
                this.getLocationStates();
                this.dialog = true;
            },
            /**
             * @param {integer} id
             */
            goTo(id) {
                this.$router.push({name: 'site', params: {siteId: id}})
            },
            /**
             * Get all the location states
             *
             * @returns {Promise<void>}
             */
            getLocationStates() {
                this.axios.get(LOCATION_STATES).then((success) => {
                    this.location_states = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },

            /**
             * Get all the location states
             *
             * @returns {Promise<void>}
             */
            getLogisticAreas() {
                this.axios.get(USER_LOGISTIC_AREAS).then((success) => {
                    this.logistic_areas = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },

            /**
             * Method used to validate the update on a site. Multiple fields are null in the request.
             * So we remove them from the request.
             *
             * @param site
             */
            validateSite(site) {

                if(site.location_state_id !== 1 && (site.longitude === '' || site.latitude === '')){
                    site.location_state_id = 1;
                }

                if(site.location_state_id === 1 && ( (site.longitude !== '' && site.longitude !== null) && (site.latitude !== '' && site.latitude !== null))){
                    site.location_state_id = 2;
                } else if (site.location_state_id !== 1 && ( site.longitude === '' || site.longitude === null || site.latitude === '' || site.latitude === null) ) {
                    site.location_state_id = 1;
                }

                for (let property in site) {
                    if (site.hasOwnProperty(property) && (site[property] == null)) {
                        site[property] = undefined;
                    }
                }

                site['last_operation_datetime'] = undefined;
                site['last_loading_datetime'] = undefined;
                site['last_delivery_datetime'] = undefined;
            },

            /**
             * Method used to update the site
             *
             * @param site
             */
            updateSite(site) {
                this.updating = true;
                this.validateSite(site);
                this.axios.patch(
                    this.replacePathParams(SITE, {site: site.id}),
                    site
                ).then(() => {
                    this.showSnackbar('success', this.$t('site.site_updated'));
                    this.refreshSites();
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                        this.close();
                    }
                }).finally(() => {
                    this.updating = false;
                    this.dialog = false;
                })
            },

            /**
             * Method used to refresh the sites of the data table
             */
            refreshSites() {
                if (this.chosen_logistic_area != null) {
                    this.getSitesFromLogisticArea(this.chosen_logistic_area);
                } else {
                    this.getSites();
                }
            },
            /**
             * Method used to set up the map with the position of the site, if it exists
             */
            setUpMap() {
                if (this.selected_site.longitude != null && this.selected_site.latitude != null) {
                    this.site_position = latLng(this.selected_site.longitude, this.selected_site.latitude);
                    this.center = latLng(this.selected_site.longitude, this.selected_site.latitude);
                    this.position_exists = true;
                } else {
                    this.center = latLng(47.35155, 5.05671);
                    this.position_exists = false;
                }
            },
            /**
             * Change options
             *
             * @param {object} options
             */
            changeOptions(options)
            {
                this.currentPage = options.page;
                this.options.itemsPerPage = options.itemsPerPage;

                if (this.chosen_logistic_area || sessionStorage.getItem('logistic-area-filter')) {
                    let filter = this.chosen_logistic_area || sessionStorage.getItem('logistic-area-filter');
                    this.getSitesFromLogisticArea(parseInt(filter));
                } else {
                    this.getSites(this.currentPage, options.itemsPerPage);
                }
            },
            /**
             * Get site's companies codes
             *
             * @param site
             * @returns {[]}
             */
            site_references(site) {
                let site_references = [];
                _.each(site.companies, (company) => {
                    site_references.push(company.code);
                });

                return site_references;
            },
            site_last_operation(site) {
                if (site.last_loading_date && site.last_delivery_date) {
                    return this.$moment(_.max([site.last_loading_date, site.last_delivery_date])).format('L LT');
                } else if (site.last_loading_date) {
                    return this.$moment(site.last_loading_date).format('L LT');
                } else if (site.last_delivery_date) {
                    return this.$moment(site.last_delivery_date).format('L LT');
                }

                return this.$t('site.no_operation');
            },
        },
        computed: {
            orderedAreas() {
                return _.orderBy(this.logistic_areas, 'code')
            },
        },
        destroyed() {
            sessionStorage.setItem('page-filter', this.currentPage);
        },
    }
</script>
