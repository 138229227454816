<template>
    <div class="tours-tracking-module">
        <v-row>
            <v-col
                class="white"
            >
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.tours_tracking'),
                                  disabled: true,
                                  href: 'breadcrumbs_tours',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3" style="padding-bottom: 0">
                <v-menu
                    v-model="date_picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    offset-y
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="dateRange"
                            :label="$t('tour.date_range')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker :locale="lang"
                                   v-model="dates"
                                   range
                                   :allowed-dates="val => allowedFutureDate(val)"
                    >
                    </v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="3" v-show="logistic_areas.length >= 1" style="padding-bottom: 0">
                <v-autocomplete
                    v-model="logistic_areas_choices"
                    :items="orderedAreas"
                    :search-input.sync="searchAreasInput"
                    @blur="getTrucks"
                    :filter="filterAreas"
                    :label="$t('tour.logistic_areas')"
                    id="logistic_area"
                    name="logistic_area"
                    item-text="code"
                    item-value="id"
                    prepend-icon="mdi-target"
                    class="px-4 mb-3"
                    :clearable="true"
                    multiple
                >
                    <v-list
                        v-if="isSelectAllAreasAvailable"
                        class="pa-0"
                    >
                        <v-list-item-group v-model="toggleAreas" class="d-flex align-center pa-0">
                            <v-list-item>
                                <v-list-item-icon class="mr-2">
                                    <v-icon :color="logistic_areas_choices.length > 0 ? 'primary' : ''">
                                        {{ iconAreas }}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-divider
                        slot="prepend-item"
                    ></v-divider>
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="3" v-show="trucks.length >= 1" style="padding-bottom: 0">
                <v-autocomplete
                    id="truck"
                    name="truck"
                    v-model="chosen_truck"
                    item-text="code"
                    item-value="id"

                    prepend-icon="mdi-truck"
                    class="px-4 mb-3"
                    :clearable="true"
                    :items="trucks"
                    :label="$t('tour.trucks')"
                    :filter="filterTrucks"
                    :search-input.sync="searchTrucksInput"
                    @change="searchTrucksInput=''"
                >
                </v-autocomplete>
            </v-col>

            <v-col cols="3">
                <v-btn :disabled="!isTruckSelected" @click="apply" color="primary">{{ $t('tour.apply') }}</v-btn>
            </v-col>

            <v-col cols="4" v-if="steps.length > 0" style="padding-top: 0">
                <v-btn :disabled="!isTruckSelected" @click="exportResults" color="primary">{{ $t('tour.export_results') }}</v-btn>
            </v-col>

            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="steps"
                    :loading="loading"
                    :page.sync="currentPage"
                    show-expand
                    single-expand
                    @item-expanded="loadOrderLines"
                    :no-data-text="$t('global.no_data')"
                    :loading-text="$t('global.loading_text')"
                    :no-results-text="$t('global.no_results_text')"
                    class="elevation-1"
                    :item-class="itemRowBackground"
                    :footer-props="{'items-per-page-options': [5,10,100],
                        'items-per-page-text': $t('global.rows_per_page')}"
                >
                    <!-- Date début prévue (steps.delivery_expected_datetime | steps.loading_expected_datetime) -->
                    <template v-slot:item.expected_starting_datetime="{ item }">
                        {{ item.expected_starting_datetime | formatDateTime }}
                    </template>

                    <!-- Date début réelle (steps.delivery_real_start_datetime || steps.loading_real_start_datetime) -->
                    <template v-slot:item.real_starting_datetime="{ item }">
                        {{ item.real_starting_datetime | formatDateTime }}
                    </template>

                    <!-- Camion (driver_step_truck.truck.code) -->
                    <template v-slot:item.truck="{ item }">
                        {{ item.truck | nullable }}
                    </template>

                    <!-- Tournée (steps.delivered_tour_number + (steps.delivery_expected_date | steps.loading_expected_date) ) -->
                    <template v-slot:item.tour="{ item }">
                        {{ item.tour | nullable }}
                    </template>

                    <!-- Ce champ n'existe pas encore -->
                    <template v-slot:item.tanker_registration="{ item }">
                        {{ item.tanker_registration | nullable }}
                    </template>

                    <!-- Kms saisies (steps.tour.mileage) -->
                    <template v-slot:item.mileage="{ item }">
                        {{ item.mileage | nullable }}
                    </template>

                    <!-- Durée saisie (steps.tour.duration) -->
                    <template v-slot:item.duration="{ item }">
                        {{ formatDate(item.duration) }}
                    </template>

                    <!-- À charger (SUM(steps.order_line_step.order_lines.mileage.product_ordered_quantity)) -->
                    <template v-slot:item.total_product_ordered_quantity="{ item }">
                        {{ item.total_product_ordered_quantity | nullable }}
                    </template>

                    <!-- Quantité chargée (SUM(steps.order_line_step.order_lines.mileage.product_loaded_quantity)) -->
                    <template v-slot:item.total_product_loaded_quantity="{ item }">
                        {{ item.total_product_loaded_quantity | nullable }}
                    </template>

                    <!-- Nb Lots (condition mix_on_delivery_order_line_id / mix_on_loading_order_line_id) -->
                    <template v-slot:item.nb_lots="{ item }">
                        {{ item.nb_lots }}
                    </template>

                    <!-- Site -->
                    <template v-slot:item.site="{ item }">
                        <div class="cell_auto">
                            {{ item.site | nullable }}
                        </div>
                    </template>

                    <!-- Commune -->
                    <template v-slot:item.place="{ item }">
                        <div class="cell_auto">{{ item.place | nullable }}</div>
                    </template>

                    <!-- Sécurité -->
                    <template v-slot:item.security="{ item }">
                        <div v-if="getBiosecurityImage(item.biosecurity)">
                            <img
                                height="32"
                                :src="getBiosecurityImage(item.biosecurity)"
                                :alt="$t('repository.pictogram')"
                            >
                        </div>
                        <span v-else>-</span>
                    </template>

                    <!-- Nettoyage intérieur -->
                    <template v-slot:item.inside_cleaning="{ item }">
                        <div class="cell_auto">
                            {{ getCleaningInside(item.inside_cleaning) }}
                        </div>
                    </template>

                    <!-- Nettoyage extérieur -->
                    <template v-slot:item.outside_cleaning="{ item }">
                        <div class="cell_auto">
                            {{ getCleaningOutside(item.outside_cleaning) }}
                        </div>
                    </template>

                    <!-- CR COMPLET / NON COMPLET -->
                    <template v-slot:item.is_completed="{ item }">
                        {{ item.is_completed | nullable}}
                    </template>

                    <!-- CR RAS / NON RAS -->
                    <template v-slot:item.cr_status="{ item }">
                        {{ item.cr_status | nullable }}
                    </template>

                    <!-- DÉTAIL -->

                    <template v-slot:item.detail="{ item }">
                        <v-icon @click="openDetailDialog(item)">
                            mdi-eye
                        </v-icon>
                    </template>

                    <template v-slot:expanded-item="{headers,item}">
                        <td :colspan="headers.length" style="margin:0; padding:30px;" >
                            <v-data-table
                                :headers="order_lines_headers"
                                :items="order_lines"
                                :loading="order_lines_loading"
                                :page.sync="order_lines_current_page"
                                :no-data-text="$t('global.no_data')"
                                :loading-text="$t('global.loading_text')"
                                :no-results-text="$t('global.no_results_text')"
                                class="elevation-1"
                                disable-pagination
                                hide-default-footer
                            >
                                <!-- Commande -->
                                <template v-slot:item.order_number="{ item }">
                                    {{ item.order_number | nullable }}
                                </template>

                                <!-- Produit -->
                                <template v-slot:item.product="{ item }">
                                    <div class="cell_auto">{{ item.product | nullable }}</div>
                                </template>

                                <!-- Mix -->
                                <template v-slot:item.mix="{ item }">
                                    {{ item.mix | nullable }}
                                </template>

                                <!-- Conditionnement -->
                                <template v-slot:item.presentation_id="{ item }">
                                    {{ item.presentation_id | nullable }}
                                </template>

                                <!-- À CHARGER -->
                                <template v-slot:item.product_ordered_quantity="{ item }">
                                    {{ item.product_ordered_quantity | nullable }}
                                </template>

                                <!-- CHARGÉ -->
                                <template v-slot:item.product_loaded_quantity="{ item }">
                                    {{ item.product_loaded_quantity | nullable }}
                                </template>

                                <!-- USINE -->
                                <template v-slot:item.company="{ item }">
                                    {{ item.company | nullable }}
                                </template>

                                <!-- CASE -->
                                <template v-slot:item.casing="{ item }">
                                    {{ item.casing | nullable }}
                                </template>

                                <!-- SILO -->
                                <template v-slot:item.silo="{ item }">
                                    {{ item.silos[0].code | nullable }}
                                </template>

                                <!-- COMM ART -->
                                <template v-slot:item.comm_art="{ item }">
                                    {{ item.comm_art | nullable }}
                                </template>

                                <!-- ACCÈS VÉHICULE -->
                                <template v-slot:item.vehicle_access="{ item }">
                                    {{ item.vehicle_access | nullable }}
                                </template>

                                <!-- ÉQUIPEMENT -->
                                <template v-slot:item.equipment="{ item }">
                                    {{ item.equipment | nullable }}
                                </template>

                                <!-- RISQUE -->
                                <template v-slot:item.risk="{ item }">
                                    <img v-if="item.silos.length > 0 && item.silos[0] && item.silos[0].security_level" :src="'images/' + getSecurityLevelImage(item.silos[0].security_level.color)" alt="security_level">
                                </template>

                                <!-- Date heure flash -->
                                <template v-slot:item.flash_qr_code="{ item }">
                                    <v-tooltip bottom v-if="item.scan !== null">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                                color="green">
                                                mdi-barcode-scan
                                            </v-icon>
                                        </template>
                                        <span v-if="item.scan">
                                            {{ item.scan.scanned_at | moment('L LT') }}
                                        </span>
                                        <span v-else>-</span>
                                    </v-tooltip>
                                    <v-icon
                                        v-else
                                        v-bind="attrs"
                                        v-on="on"
                                        color="orange">
                                        mdi-barcode-scan
                                    </v-icon>
                                </template>

                                <!-- DATE HEURE PURGE -->
                                <template v-slot:item.purge_datetime="{ item }">
                                    <span v-if="item.purge_datetime">
                                        {{ item.purge_datetime | moment('L LT') }}
                                    </span>
                                    <span v-else>-</span>
                                </template>

                                <!-- PURGE -->
                                <template v-slot:item.the_purge="{ item }">
                                    {{ item.the_purge | nullable }}
                                </template>

                            </v-data-table>
                        </td>
                    </template>

                </v-data-table>
            </v-col>
        </v-row>

        <v-dialog
            v-model="detail_dialog"
            v-if="detail_dialog"
        >
            <tours_tracking_detail :selected_step="step"
                                   :step_id="step_id"
                                   :site_id="site_id"
                                   :inside_cleanings="inside_cleanings"
                                   :outside_cleanings="outside_cleanings"
                                   @close-detail="closeDetailDialog"/>
        </v-dialog>
    </div>
</template>

<style lang="scss" scoped>
    .btn--steps {
        min-width: 28px !important;
        padding: 0 !important;
        margin: 5px 10px !important;
    }
</style>

<script>
import {
    AREA_TRUCKS, BIOSECURITIES, INSIDE_CLEANINGS, OUTSIDE_CLEANINGS, STEP_DRIVER, STEP_ORDER_LINES,
    TOURS_TRACKING, TOURS_TRACKING_EXPORT,
    USER_LOGISTIC_AREAS,
} from "../api";
    import StepDetail from '../components/Step/Detail';
    import _ from "lodash";
import moment from "moment";
import Tours_tracking_detail from "../components/Tours/ToursTrackingDetail.vue";
    export default {
        name: "tour",
        data() {
            return {
                headers: [
                    {text: this.$t('tour.expected_starting_date'), value: 'expected_starting_datetime', width: '100px', sortable: false},
                    {text: this.$t('tour.expected_real_date'), value: 'real_starting_datetime', width: '100px', sortable: false},
                    {text: this.$t('tour.car'), value: 'truck', width: '50px', sortable: false},
                    {text: this.$t('tour.tour'), value: 'tour', width: '150px', sortable: false},
                    {text: this.$t('tour.cistern_number_plate'), value: 'tanker_registration', width: '100px', sortable: false},
                    {text: this.$t('tour.km_written'), value: 'mileage', width: '100px', sortable: false},
                    {text: this.$t('tour.tour_duration_written'), value: 'duration', width: '100px', sortable: false},
                    {text: this.$t('tour.to_load'), value: 'total_product_ordered_quantity', width: '100px', sortable: false},
                    {text: this.$t('tour.loaded_quantity'), value: 'total_product_loaded_quantity', width: '100px', sortable: false},
                    {text: this.$t('tour.nb_lots'), value: 'nb_lots', width: '100px', sortable: false},
                    {text: this.$t('tour.site'), value: 'site', width: '100px', sortable: false},
                    {text: this.$t('tour.city'), value: 'place', width: '100px', sortable: false},
                    {text: this.$t('tour.security'), value: 'security', width: '100px', sortable: false},
                    {text: this.$t('tour.inside_cleaning'), value: 'inside_cleaning', width: '100px', sortable: false},
                    {text: this.$t('tour.outside_cleaning'), value: 'outside_cleaning', width: '100px', sortable: false},
                    {text: this.$t('tour.report_status'), value: 'is_completed', width: '100px', sortable: false},
                    {text: this.$t('tour.status_cr'), value: 'cr_status', width: '100px', sortable: false},
                    {text: this.$t('tour.detail'), value: 'detail', width: '100px', sortable: false},
                ],
                order_lines_headers: [
                    {text: this.$t('tour.order'), value: 'order_number', width: '100px', sortable: false},
                    {text: this.$t('tour.product'), value: 'product', width: '100px', sortable: false},
                    {text: this.$t('tour.mix'), value: 'mix', width: '100px', sortable: false},
                    {text: this.$t('tour.presentation'), value: 'presentation_id', width: '100px', sortable: false},
                    {text: this.$t('tour.to_load'), value: 'product_ordered_quantity', width: '100px', sortable: false},
                    {text: this.$t('tour.loaded_quantity'), value: 'product_loaded_quantity', width: '100px', sortable: false},
                    {text: this.$t('tour.factory'), value: 'company', width: '100px', sortable: false},
                    {text: this.$t('tour.case'), value: 'casing', width: '100px', sortable: false},
                    {text: this.$t('tour.silo'), value: 'silo', width: '100px', sortable: false},
                    {text: this.$t('tour.comm_art'), value: 'comm_art', width: '100px', sortable: false},
                    {text: this.$t('tour.access'), value: 'vehicle_access', width: '100px', sortable: false},
                    {text: this.$t('tour.equipment'), value: 'equipment', width: '100px', sortable: false},
                    {text: this.$t('tour.risk'), value: 'risk', width: '100px', sortable: false},
                    {text: this.$t('tour.flash_qr_code'), value: 'flash_qr_code', width: '100px', sortable: false},
                    {text: this.$t('tour.purge_datetime'), value: 'purge_datetime', width: '100px', sortable: false},
                    {text: this.$t('tour.purge'), value: 'the_purge', width: '100px', sortable: false},
                ],
                order_lines: [],
                steps: [],
                order_lines_loading: false,
                loading: false,
                dates: [this.$moment().format('YYYY-MM-DD'), this.$moment().add(1, 'days').format('YYYY-MM-DD')],
                date_picker: false,
                logistic_areas: [],
                logistic_areas_choices: [],
                toggleAreas: null,
                searchAreasInput: '',
                trucks: [],
                chosen_truck: null,
                searchTrucksInput: '',
                lang,
                order_lines_current_page: 1,
                currentPage:1,
                biosecurities: [],
                detail_dialog: false,
                inside_cleanings: [],
                outside_cleanings: [],
                step: {},
                step_id: null,
                site_id: null

            }
        },
        components: {Tours_tracking_detail, StepDetail},
        mounted() {
            this.getLogisticAreas();
            this.getTrucks();
            this.getBiosecurities();
            this.getInsideCleanings();
            this.getOutsideCleanings();
        },
        watch: {
            /**
             * Select all areas or not
             */
            toggleAreas() {
                this.$nextTick(() => {
                    if (this.allAreas) {
                        this.logistic_areas_choices = []
                    } else {
                        this.logistic_areas.forEach(element => {
                            if (!this.logistic_areas_choices.includes(element.id)) {
                                this.logistic_areas_choices.push(element.id);
                            }
                        });
                    }
                })
            },
        },
        computed: {
            isTruckSelected(){
                return this.chosen_truck
            },
            moment() {
                return moment
            },
            isSelectAllAreasAvailable() {
                return this.searchAreasInput === '' || this.searchAreasInput === null;
            },
            dateRange() {
                return [this.$moment(this.dates[0]).format('L'), this.$moment(this.dates[1]).format('L')].join(' ➤ ')
            },
            allAreas() {
                return this.logistic_areas_choices.length === this.logistic_areas.length
            },
            someAreas() {
                return this.logistic_areas_choices.length > 0 && !this.allAreas
            },
            iconAreas() {
                if (this.allAreas) return 'mdi-close-box'
                if (this.someAreas) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
            orderedAreas() {
                return _.orderBy(this.logistic_areas, 'code')
            },
        },
        methods: {
            /**
             * Filter areas drop-down
             */
            filterAreas(item, queryText) {
                let textOne = "";
                let textTwo = "";
                let searchText = "";
                if (item.code && item.name) {
                    textOne = item.name.toLowerCase();
                    textTwo = item.code.toLowerCase();
                    searchText = queryText.toLowerCase();
                }

                return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
            },
            /**
             * Filter trucks drop-down
             */
            filterTrucks(item, queryText) {
                var text = "";
                var searchText = "";
                if (item.code) {
                    text = item.code.toLowerCase();
                    searchText = queryText.toLowerCase();
                }

                return text.indexOf(searchText) > -1;
            },
            /**
             * Get all the location states
             *
             * @returns {Promise<void>}
             */
            getLogisticAreas() {
                this.axios.get(USER_LOGISTIC_AREAS).then((success) => {
                    this.logistic_areas = _.orderBy(success.data, ['name']);
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Get all the trucks
             *
             * @returns {Promise<void>}
             */
            getTrucks() {
                this.axios.get(
                    AREA_TRUCKS,
                    {
                        params: {
                            logistic_areas: this.logistic_areas_choices
                        },
                    }
                ).then((success) => {
                    this.chosen_truck = null;
                    this.trucks = _.orderBy(success.data, ['code']);
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                })
            },
            /**
             * Get steps
             */
            getSteps() {
                this.loading = true;
                this.axios.get(TOURS_TRACKING, {
                    params: {dates: this.dates, truck: this.chosen_truck}
                }).then((success) => {
                    this.steps = success.data;
                }).finally(() => {
                    this.loading = false;
                    this.currentPage = 1
                });
            },
            allowedFutureDate(val) {
                if (this.dates[0] && !this.dates[1]) {
                    const maxDateForward = this.$moment(this.dates[0]).add(7, 'days');
                    return this.$moment(val).isBetween(this.dates[0], maxDateForward, null, '[]');
                } else if (this.dates[1]) {
                    const diffDays = this.$moment(this.dates[1]).diff(this.$moment(this.dates[0]), 'days');
                    return diffDays <= 7 && diffDays >= 0;
                } else {
                    return true;
                }
            },
            apply() {
                this.getSteps();
            },
            exportResults(){
                this.download()
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Method used to retrieve biosecurities
             * @returns {Promise<void>}
             */
            async getBiosecurities(){
                this.loading = true;
                let response = await this.axios.get(BIOSECURITIES);
                this.biosecurities = response.data
                this.loading = false;
            },
            /**
             * Method used to retrieve biosecurity image
             *
             * @param biosecurity
             * @returns {*|null}
             */
            getBiosecurityImage(biosecurity){
                if(!biosecurity) return null
                let foundBiosecurity = this.biosecurities.find(item => item.code === biosecurity.code);
                if (foundBiosecurity) {
                    return foundBiosecurity.logo_url;
                } else {
                    return null;
                }
            },
            /**
             * Method used to format a list of inside cleanings id into a list of inside cleaning names
             *
             * @param list
             * @returns {string}
             */
            getCleaningInside(list){
                if(!!list) {
                    let cleanings = list.split(";");
                    let valid_cleanings = [];

                    cleanings.forEach(cleaning => {
                        if(this.cleaningExists(cleaning, this.inside_cleanings)) valid_cleanings.push(cleaning);
                    })

                    const formatted_cleanings = valid_cleanings.map(cleaning => this.getCleaningById(cleaning, this.inside_cleanings)?.name);

                    return formatted_cleanings.join('; ')
                }

                return '-'
            },
            /**
             * Method used to format a list of outside cleanings id into a list of outside cleaning names
             *
             * @param list
             * @returns {string}
             */
            getCleaningOutside(list){

                if(!!list) {
                    let cleanings = list.split(";");
                    let valid_cleanings = [];

                    cleanings.forEach(cleaning => {
                        if(this.cleaningExists(cleaning, this.outside_cleanings)) valid_cleanings.push(cleaning);
                    })

                    const formatted_cleanings = valid_cleanings.map(cleaning => this.getCleaningById(cleaning, this.outside_cleanings)?.name);

                    return formatted_cleanings.join('; ')
                }

                return '-'
            },

            /**
             * Method used to get a cleaning with is id
             *
             * @param id
             * @param items
             * @returns {{}}
             */
            getCleaningById(id, items){
                return items.find(item => item.id === parseInt(id));
            },

            /**
             * Method used to check if a cleaning exists
             *
             * @param id
             * @param items
             * @returns {boolean}
             */
            cleaningExists(id, items){
                let item = items.find(item => item.id === parseInt(id));
                return !!item;
            },
            /**
             * Method used to get the inside cleanings
             */
            getInsideCleanings() {
                this.loading = true;
                this.axios.get(INSIDE_CLEANINGS).then(success => {
                    this.inside_cleanings = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                })
            },
            /**
             * Method used to get the outside cleanings
             */
            getOutsideCleanings() {
                this.loading = true;
                this.axios.get(OUTSIDE_CLEANINGS).then(success => {
                    this.outside_cleanings = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                })
            },
            /**
             * Method used to open the detail dialog
             *
             * @param step
             */
            openDetailDialog(step){
                this.step = step
                this.step_id = step.id
                this.site_id = step.site_id
                this.detail_dialog = true
            },
            closeDetailDialog(){
                this.detail_dialog = false
            },
            /**
             * Method used to load the order_lines that a related to the given step
             *
             * @param data
             */
            async loadOrderLines(data){
                this.order_lines = []
                this.order_lines_loading = true
                let response = await this.axios.get(
                    this.replacePathParams(STEP_ORDER_LINES, {step: data.item.id})
                );
                this.order_lines = response.data
                this.order_lines_loading = false
            },
            /**
             * Permet de déterminer le couleur de la ligne en fonction du type
             *
             * @param item
             * @returns {string}
             */
            itemRowBackground: function (item) {
                return item.type === 'LOADING' ? 'loading' : 'delivery'
            },
            /**
             * Get pictogram for the security level
             *
             * @param securityLevelColor
             * @returns {string}
             */
            getSecurityLevelImage(securityLevelColor) {
                switch (securityLevelColor) {
                    case 'Green':
                        return 'silo_vert.svg';
                    case 'Orange':
                        return 'silo_orange.svg';
                    case 'Red':
                        return 'silo_rouge.svg';
                    case 'Black':
                        return 'silo_noir.svg';
                    default:
                        return 'silo_neutre.svg';
                }
            },
            /**
             * Permet de télécharger le fichier csv
             *
             * @returns {Promise<void>}
             */
            async download() {
                let response = await this.axios.get(TOURS_TRACKING_EXPORT, {
                    params: {
                        dates: this.dates,
                        truck: this.chosen_truck
                    },
                    responseType: 'arraybuffer',
                });

                const contentDisposition = response.headers['content-disposition'];
                const match = contentDisposition && contentDisposition.match(/filename=(?:"([^"]+)"|([^;]+))/);
                const filename = match ? (match[1] || match[2]) : 'download.csv';
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            },
            formatDate(duration){
                if(this.isValidDuration(duration)){
                    return moment.utc(duration, "HH:mm:ss").format("HH:mm")
                }

                return '-'
            },
            isValidDuration(duration) {
                return /^\d{2}:\d{2}:\d{2}$/.test(duration);
            }
        },
        filters: {
            formatDateTime(value) {
                return value ? moment(value).format('L LT') : '-';
            },
            nullable(value) {
                return value ? value : '-';
            }
        }
    }
</script>

<style lang="scss">

.v-breadcrumbs li:last-child a {
    font-size: 1.25rem !important;
    color: rgba(0, 0, 0, .6) !important;
    font-weight: 500;
}

.tours-tracking-module {
    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

.loading {
    background-color: #f8eadc
}
.delivery {
    background-color: #fff
}

.cell_auto{
    white-space: nowrap;
    width: auto;
}

</style>
