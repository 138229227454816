<template>

    <div class="silo-module">

        <v-row class="align-items-center limit-height" style="position: relative;top: -20px;" >

            <v-col class="flex-grow-0">
                <v-btn :title="$t('site.back')" icon x-large
                       @click="$router.push({name: 'site', params: {siteId: silo.site.id}})">
                    <v-icon>mdi-arrow-left-thick</v-icon>
                </v-btn>
            </v-col>

            <v-col class="white">
                <v-breadcrumbs
                    :items="[
                        {
                          text: $t('navigation.sites'),
                          disabled: false,
                          href: '#/sites',
                        },
                        {
                          text: silo.site.name,
                          disabled: false,
                          href: `/#/site/${silo.site.id}`,
                        },
                        {
                          text: silo.code,
                          disabled: true,
                          href: 'breadcrumbs_dashboard',
                        },
                    ]"
                />
            </v-col>

            <v-col class="flex-grow-0">
                <div class="d-flex">
                    <div v-if="silo.last_security_action" class=" caption last-action">
                        <span class="font-weight-bold text-subtitle-1">
                            {{ silo.last_security_action.type.description }}
                        </span>
                        <br>
                        <span style="white-space: nowrap">
                            Le
                            {{ silo.last_security_action.date_action | moment('L') }}
                            à
                            {{ silo.last_security_action.date_action | moment('LT') }}
                        </span>
                    </div>
                    <v-icon
                        :title="$t('silo.edit')"
                        x-large
                        @click="editSilo()"
                    >
                        mdi-lead-pencil
                    </v-icon>
                    <img v-if="silo.security_level" :src="'images/' + getSecurityLevelImage(silo.security_level.color)"
                         alt="" width="54">
                    <img v-else alt="" src="images/silo_neutre.svg" width="54">
                </div>
            </v-col>

        </v-row>

        <v-row>
            <v-col >
                <v-container >
                    <v-row>
                        <v-col style="position: relative; top: -30px; z-index: 1">
                            <v-list class="row" dense disabled>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-music-accidental-sharp</v-icon>
                                            {{ $t('silo.id') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="silo">
                                            {{ this.silo.code || $t('silo.none') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{ $t('silo.none') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-source-branch</v-icon>
                                            {{ $t('silo.type') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="silo.silo_type">
                                            {{ this.silo.silo_type.name || $t('silo.none') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{ $t('silo.none') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-paw</v-icon>
                                            {{ $t('silo.production') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="silo.category">
                                            {{ this.silo.category.name || $t('silo.none') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{ $t('silo.none') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-hammer-wrench</v-icon>
                                            {{ $t('silo.equipment') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="silo.equipment">
                                            {{ this.silo.equipment.name || $t('silo.none') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{ $t('silo.none') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-road-variant</v-icon>
                                            {{ $t('silo.vehicle_accesses') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="silo.vehicle_access">
                                            {{ this.silo.vehicle_access.name || $t('silo.none') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{ $t('silo.none') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-crosshairs-gps</v-icon>
                                            {{ $t('silo.geolocation') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                        <span v-if="silo.longitude && silo.latitude">
                                            {{ Number.parseFloat(silo.latitude).toFixed(3) }}, {{ Number.parseFloat(silo.longitude).toFixed(3) }}
                                        </span>
                                            <span v-else>
                                            0, 0
                                        </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-calendar</v-icon>
                                            {{ $t('silo.creation_date') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="silo.created_at">
                                            {{ silo.created_at | moment('L') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{$t('silo.none')}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-clipboard-check-multiple</v-icon>
                                            {{ $t('silo.last_inventory') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="silo.last_inventory_date">
                                            {{ silo.last_inventory_date | moment('L LT') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{ $t('silo.none') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-domain</v-icon>
                                            {{ $t('silo.building') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ silo.building_name||$t('silo.none') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-tools</v-icon>
                                            {{ $t('silo.last_operation') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="silo.last_delivery_datetime">
                                            {{ silo.last_delivery_datetime | moment('L LT') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{ $t('silo.none') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-message</v-icon>
                                            {{ $t('silo.comment') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="silo.comments" class="text-wrap">
                                            {{ silo.comments }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{$t('silo.none')}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-qrcode</v-icon>
                                            {{ $t('silo.identification') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="silo.scan_code"
                                            class="text-wrap">
                                            {{ silo.scan_code }}
                                            <br>
                                            <i
                                                style="font-size: 0.65rem">{{silo.scan_code_linked_at |
                                                moment('L LT')}}</i>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            -
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="4" style="position:relative">
                            <div class="container_lock_unlock_gps_coordinate">
                                <v-btn
                                    :disabled="updating || (silo.location_state.code !== LOCKED && silo.location_state.code !== SPECIFIED) || (silo.longitude === null && silo.latitude ===null)"
                                    class="container_lock_unlock_gps_coordinate__button"
                                    color="primary"
                                    fab
                                    x-large
                                    @click="updateSiloLocationState()"
                                >
                                    <v-icon
                                        v-if="silo.location_state.code === SPECIFIED||silo.location_state.code === null || silo.location_state.code === this.NOT_SPECIFIED"
                                        :title="getTitleFromGps(silo.location_state.code)"
                                    >
                                        mdi-lock-open-variant
                                    </v-icon>
                                    <v-icon
                                        v-else
                                        :title="getTitleFromGps(silo.location_state.code)"
                                    >
                                        mdi-lock
                                    </v-icon>
                                </v-btn>
                            </div>
                            <maps
                                :latitude="silo.latitude"
                                :longitude="silo.longitude"
                                @updateCoordinates="updateCoordinates"
                            ></maps>
                        </v-col>
                    </v-row>
                </v-container>

                <v-divider></v-divider>

                <v-container>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-tabs
                                v-model="tab"
                                background-color="accent-4"
                                centered
                                icons-and-text
                            >
                                <v-tabs-slider></v-tabs-slider>

                                <div class="d-flex" style="width: 100%;">
                                    <v-tab href="#tab-information">{{ $t('silo.information') }}</v-tab>
                                    <!-- <v-tab href="#tab-orders-history">{{ $t('silo.orders_history') }}</v-tab> //masqué à la demande d'AVRIL : Jira 155 -->
<!--                                    <v-tab href="#tab-security-history">{{ $t('silo.security_history') }}</v-tab>-->
                                    <v-tab href="#tab-pictures">{{ $t('silo.pictures') }}</v-tab>
                                    <v-tab href="#tab-actions">{{ $t('silo.security_history') }}</v-tab>
                                </div>
                                <v-tab-item
                                    :value="'tab-information'">
                                    <information :silo_selected="silo"
                                                 @updateRisksInvolved="onUpdateRisksInvolved"></information>
                                </v-tab-item>
                                <v-tab-item
                                    :value="'tab-orders-history'">
                                    <orders-history :silo_selected="silo"></orders-history>
                                </v-tab-item>
                                <v-tab-item
                                    :value="'tab-security-history'">
                                    <risks-history :silo_selected="silo"></risks-history>
                                </v-tab-item>
                                <v-tab-item
                                    :value="'tab-pictures'">
                                    <pictures :silo_selected="silo"></pictures>
                                </v-tab-item>
                                <v-tab-item
                                    :value="'tab-actions'">
                                    <actions :silo_selected="silo"
                                             @action-added="handleAddedAction"
                                    ></actions>
                                </v-tab-item>
                            </v-tabs>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="600px">

            <v-card>

                <v-list>
                    <v-subheader>{{ $t('alert.treated_silo') }}</v-subheader>
                </v-list>

                <v-card-text>

                    <v-form name="silos">

                        <v-text-field
                            id="silo.code"
                            v-model="silo_to_update.code"
                            :label="$t('silo.code')"
                            data-vv-name="silo.code"
                            name="silo.code"
                            prepend-icon="mdi-alpha-c-box"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            id="silo.name"
                            v-model="silo_to_update.name"
                            :label="$t('alert.name')"
                            data-vv-name="silo.name"
                            name="silo.name"
                            prepend-icon="mdi-rename-box"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            id="silo.comment"
                            v-model="silo_to_update.comments"
                            :label="$t('silo.comment')"
                            data-vv-name="silo.comment"
                            name="silo.comment"
                            prepend-icon="mdi-comment-text-multiple-outline"
                            type="text"
                        ></v-text-field>

                        <v-select
                            id="silo.equipment"
                            v-model="silo_to_update.equipment_id"
                            v-validate="'required'"
                            :items="equipment"
                            :label="$t('silo.equipment')"
                            chips
                            data-vv-name="equipment"
                            item-text="name"
                            item-value="id"
                            name="silo.equipment"
                            prepend-icon="mdi-toolbox"
                            required
                        >
                        </v-select>

                        <v-select
                            id="silo.accesses"
                            v-model="silo_to_update.access_id"
                            v-validate="'required'"
                            :items="accesses"
                            :label="$t('alert.accesses')"
                            chips
                            data-vv-name="accesses"
                            item-text="name"
                            item-value="id"
                            name="silo.accesses"
                            prepend-icon="mdi-access-point"
                            required
                        >
                        </v-select>

                        <v-select
                            id="silo.location_states"
                            v-model="silo_to_update.location_state_id"
                            v-validate="'required'"
                            :disabled="true"
                            :items="location_states"
                            :label="$t('alert.location_state')"
                            chips
                            data-vv-name="location_states"
                            item-text="name"
                            item-value="id"
                            name="silo.location_states"
                            prepend-icon="mdi-crosshairs"
                            required
                        >
                        </v-select>

                        <v-text-field
                            id="silo.longitude"
                            v-model="silo_to_update.longitude"
                            :label="$t('alert.longitude')"
                            data-vv-name="silo.longitude"
                            name="silo.longitude"
                            prepend-icon="mdi-longitude"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            id="silo.latitude"
                            v-model="silo_to_update.latitude"
                            :label="$t('alert.latitude')"
                            data-vv-name="silo.latitude"
                            name="silo.latitude"
                            prepend-icon="mdi-latitude"
                            type="text"
                        ></v-text-field>

                        <v-select
                            id="silo.silo_types"
                            v-model="silo_to_update.silo_type_id"
                            v-validate="'required'"
                            :items="silo_types"
                            :label="$t('alert.silo_type')"
                            chips
                            data-vv-name="silo_types"
                            item-text="name"
                            item-value="id"
                            name="silo.silo_types"
                            prepend-icon="mdi-clock-outline"
                            required
                        >
                        </v-select>

                        <v-text-field
                            id="silo.scan_code"
                            v-model="silo_to_update.scan_code"
                            :label="$t('silo.identification')"
                            data-vv-name="silo.scan_code"
                            name="silo.scan_code"
                            prepend-icon="mdi-qrcode"
                            type="text"
                            clearable
                        ></v-text-field>

                        <v-checkbox
                            v-model="silo_to_update.scan_code_pairing_is_disabled"
                            :label="$t('silo.disable_qrcode_pairing')"
                        />

                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn color="blue darken-1" text @click="dialog = false">{{ $t('user.cancel') }}</v-btn>
                            <v-btn @click="updateSilo()">
                                {{ $t('alert.update') }}
                            </v-btn>
                        </v-card-actions>

                    </v-form>

                </v-card-text>

            </v-card>

        </v-dialog>

        <v-snackbar v-model="snackbar" :color="snackbar_type" top>
            {{ snackbar_text }}
            <v-btn text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>

    </div>

</template>

<script>
import Information from "../components/Silo/Information";
import RisksHistory from "../components/Silo/RisksHistory";
import {EQUIPMENTS, LOCATION_STATES, SILO, SILO_TYPES, SILOS, VEHICLE_ACCESSES} from "../api";
import OrdersHistory from "../components/Silo/OrdersHistory";
import Pictures from "../components/Silo/Pictures";
import Maps from "../components/Maps";
import Actions from "../components/Silo/Actions";

var map = {
    remove: () => {
    }
};

export default {
    name: "Silo",
    components: {OrdersHistory, Information, RisksHistory, Pictures, Maps, Actions},
    data() {
        return {
            silo: {
                location_state: {},
                site: {},
                security_level: {},
                silo_type: {},
                category: {},
                equipment: {},
                vehicle_access: {},
                risks: []
            },
            silo_to_update: {},
            equipment: [],
            accesses: [],
            location_states: [],
            silo_types: [],
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
            updating: false,
            dialog: false,
            position_exists: false,
            tab: null,
            UNSPECIFIED: null,
            SPECIFIED: 'GPS_OK',
            LOCKED: 'GPS_VERR',
            NOT_SPECIFIED: 'GPS_NR'
        }
    },
    computed: {
        location_state_code() {
            return this.silo.location_state.code === this.UNSPECIFIED ||
            this.silo.location_state.code === this.SPECIFIED ? this.LOCKED : this.SPECIFIED;
        },
    },
    async mounted() {
        await this.getSilo(this.$route.params.siloId);
        await this.getLocationStates();
        await this.getEquipments();
        await this.getVehicleAccesses();
        await this.getLocationStates();
        await this.getSiloTypes();
        await this.getSiloTypes();
    },
    methods: {
        editSilo() {
            Object.assign(this.silo_to_update, this.silo);
            this.dialog = true;
        },
        /**
         * Get all the vehicle accesses
         *
         * @returns {Promise<void>}
         */
        getVehicleAccesses() {
            this.axios.get(VEHICLE_ACCESSES).then((success) => {
                this.accesses = success.data
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },
        /**
         * Get all the silo types
         *
         * @returns {Promise<void>}
         */
        getSiloTypes() {
            this.axios.get(SILO_TYPES).then((success) => {
                this.silo_types = success.data
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },
        /**
         * Get all the equipments
         *
         * @returns {Promise<void>}
         */
        getEquipments() {
            this.axios.get(EQUIPMENTS).then((success) => {
                this.equipment = success.data
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },
        /**
         * Get pictogram for the security level
         *
         * @param securityLevelColor
         * @returns {string}
         */
        getSecurityLevelImage(securityLevelColor) {
            switch (securityLevelColor) {
                case 'Green':
                    return 'silo_vert.svg';
                case 'Orange':
                    return 'silo_orange.svg';
                case 'Red':
                    return 'silo_rouge.svg';
                case 'Black':
                    return 'silo_noir.svg';
                default:
                    return 'silo_neutre.svg';
            }
        },
        onUpdateRisksInvolved(silo) {
            this.silo = silo;
        },
        getSilo(silo) {
            this.axios.get(
                this.replacePathParams(SILO, {silo})
            ).then((success) => {
                this.silo = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            })
        },
        /**
         * Method used to update the silo location state
         */
        updateSiloLocationState() {
            this.updating = true;
            this.axios.patch(
                this.replacePathParams(SILO, {silo: this.silo.id}),
                {
                    location_state_id: _.get(_.find(this.location_states, {code: this.location_state_code}), 'id'),
                }
            ).then((success) => {
                this.showSnackbar('success', this.$t('silo.silo_updated'));
                this.silo = success.data;
            }).catch((error) => {
                if (error.response.status === 422) {
                    this.showSnackbar('warning', this.parseLaravelError(error));
                } else {
                    this.showSnackbar('error', this.$t('global.error'));
                }
            }).finally(() => {
                this.updating = false;
            })
        },
            /**
             * Method used to update the silo
             */
            updateSilo() {
                this.validateSilo(this.silo_to_update);
                delete this.silo_to_update.scan_code_linked_at
                this.axios.patch(
                    SILOS + '/' + this.silo_to_update.id,
                    _.omit(this.silo_to_update, 'risks')
                ).then((success) => {
                    this.showSnackbar('success', this.$t('alert.silo_updated'));
                    Object.assign(this.silo, success.data);
                    this.dialog = false;
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                        this.dialog = false;
                    }
                }).finally(() => {
                    this.dialog = false;
                })
            },
            validateSilo(silo) {
                if (silo.location_state_id !== 1 && (silo.longitude === '' || silo.latitude === '')) {
                    silo.location_state_id = 1;
                }
                if ((silo.location_state_id === 1 || silo.location_state_id === null) && ((silo.longitude !== '' && silo.longitude !== null) && (silo.latitude !== '' && silo.latitude !== null))) {
                    silo.location_state_id = 2;
                } else if (silo.location_state_id !== 1 && (silo.longitude === '' || silo.longitude === null || silo.latitude === '' || silo.latitude === null)) {
                    silo.location_state_id = 1;
                }
            },
            /**
             * Get the title for the associated GPS
             *
             */
            getTitleFromGps(location_state_code) {
                switch (location_state_code) {
                    case this.LOCKED:
                        return this.$t('silo.unlock');
                    default:
                        return this.$t('silo.lock');
                }
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get all location state
             */
            getLocationStates() {
                this.axios.get(LOCATION_STATES).then((success) => {
                    this.location_states = success.data;
                })
            },
            updateCoordinates(data) {
                this.updating = true;
                this.axios.patch(
                    this.replacePathParams(SILO, {silo: this.$route.params.siloId}),
                    data
                ).then((success) => {
                    this.showSnackbar('success', this.$t('alert.silo_updated'));
                    Object.assign(this.silo, success.data);
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.dialog = false;
                })
            },
        handleAddedAction() {
            this.getSilo(this.$route.params.siloId)
        }
    },
    watch: {
        tab(value) {
            if (value === 'tab-actions') {
                this.silo = this.getSilo(this.silo.id)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.container_lock_unlock_gps_coordinate {
    position: absolute;
    z-index: 2;
    top: calc(50% - 36px);
    left: -24px;
}
</style>

<style lang="scss">
.sub-offset{

}
    .limit-height{
        z-index: 2;
        max-height: 50px;
    }
    .align-items-center {
        align-items: center;
    }
    .silo-module {
        .v-breadcrumbs li:last-child a {
            font-size: 1.25rem !important;
            color: rgba(0, 0, 0, .6) !important;
            font-weight: 500;
        }

        .v-list--dense .v-list-item {
            .v-list-item__title,
            .v-list-item__subtitle {
                font-size: 0.9rem;
            }
        }
    }

.last-action {
    min-width: 18rem;
    text-transform: uppercase;
}
</style>
